<template>
  <vuedraggable
    class="custom-draggable"
    v-model="dragList"
    :tag="wrapTag"
    draggable=".draggable-item"
    animation="300"
    @start="onDragStart"
    @end="onDragEnd"
  >
    <transition-group
      tag="div"
      class="transition-el"
      :class="[vertical ? 'vertical' : '']"
    >
      <!-- 拖拽元素 -->
      <div
        v-for="(item, index) in dragList"
        class="draggable-item"
        :class="[vertical ? 'vertical-item' : '']"
        :key="item + index"
      >
        <slot v-bind:item="item" v-bind:index="index">
          <div class="list-item" :class="[vertical ? 'vertical-item' : '']">
            <i class="el-icon-rank"></i>&nbsp;
            {{ item[keyName] }}
          </div>
        </slot>
      </div>
    </transition-group>
  </vuedraggable>
</template>

<script>
import vuedraggable from "vuedraggable";
import { guid } from "@/utils/common";
export default {
  components: { vuedraggable },
  props: {
    wrapTag: {
      type: String,
      default: "div",
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    keyName: { type: String, default: "name" },
    sortKey: {
      type: String,
      default: "seq",
    },
    syncSortKey: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragList: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.dragList = this.value.map((item) => item);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    guid,
    onDragStart(e) {
      e.target.classList.add("hideShadow");
    },
    onDragEnd(e) {
      e.target.classList.remove("hideShadow");
      if (this.syncSortKey) {
        this.dragList = this.dragList.map((item, index) => {
          item[this.sortKey] = index;
          return item;
        });
      }
      this.$emit("input", this.dragList);
      this.$emit("dragEnd", this.dragList);
    },
  },
};
</script>

<style lang="scss" scoped>
// 拖拽
.custom-draggable {
  box-sizing: border-box;
  width: 100%;
  max-width: 300px;
  .transition-el {
    box-sizing: border-box;
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    &.vertical {
      display: block;
      padding-right: 5px;
    }
  }
  .draggable-item {
    position: relative;
    overflow: hidden;
    &.vertical-item {
      display: block;
      width: 100%;
    }
  }
  .list-item {
    box-sizing: border-box;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #333;
    text-align: left;
    padding: 0 10px;
    background-color: #fff;
    margin: 5px;
    cursor: pointer;
    transition: all 0.3s linear;
    overflow: hidden;
    &.vertical-item {
      display: block;
      width: 100%;
    }
  }
}
</style>